import { analyticsService } from '@/services/analytics-service';

import { taboolaTrackStartCheckout } from '@/lib/event-trackers';
import { logger } from '@listening/shared';

const removeTrailingSlash = (str: string) => str.replace(/\/$/, '');

export type CTAButtonContext = {
  label: string;
  href: string;
  analyticsCheckout: boolean;
};

export function CTAButtonTryApp({
  className,
  label,
  href,
  analyticsCheckout,
}: {
  className: string;
} & CTAButtonContext) {
  return (
    <a
      data-cta-button
      onClick={(e) => {
        logger.log('CTA button clicked');
        if (analyticsCheckout) {
          analyticsService.trackLogCtaClicked();
          taboolaTrackStartCheckout();
        }

        const clickedAnchor = e.target as HTMLAnchorElement;
        const currentPathName = removeTrailingSlash(location.pathname);
        const clickedPathName = removeTrailingSlash(clickedAnchor.pathname);
        const clickLocationIsSamePage =
          currentPathName == clickedPathName &&
          location.hostname == clickedAnchor.hostname;

        if (!clickLocationIsSamePage || !clickedAnchor.hash) return;

        const hashTarget = document.querySelector(clickedAnchor.hash);
        if (hashTarget) {
          e.preventDefault();
          window.scrollTo({
            top: hashTarget.getBoundingClientRect().top + window.scrollY,
            behavior: 'smooth',
          });
        }
      }}
      href={href}
      className={className}
    >
      {label}
    </a>
  );
}
